import { Injectable } from '@angular/core';

const getLocalStorage = (): Storage => localStorage;

@Injectable({ providedIn: 'root' })
export class LocalStorageRefService {
  get localStorage(): Storage {
    return getLocalStorage();
  }
}
